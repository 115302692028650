import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import HeaderCart from "../../../components/Layout/Header/HeaderCart";
import MyRoute from "../../../hoc/MyRoute";
import MyCart from "../MyCart/components/MyCart";
import { getCartList, clearCartList } from "./redux/action";
import { addToCart, addCart, getCartItemCount } from "../Redeem/redux/action";
import { merchantDetails } from "../../Screens/Dashboard/redux/action";
import { apiData } from "../../../common/common-types";
import { getStorageItem } from "../../../common/localStorage";
import {
  changeActiveTab,
  showLoader,
  logoutUser,
} from "../../../../src/App/redux/action";
import AlertMessage from "../../../common/AlertMessage/AlertMessage";
import { Grid } from "@material-ui/core";
import Footer from "../../../components/Layout/Footer/Footer";

const mapDispatchToProps = {
  getCartList,
  addToCart,
  addCart,
  getCartItemCount,
  merchantDetails,
  changeActiveTab,
  showLoader,
  logoutUser,
  clearCartList,
};

const mapStateToProps = (state) => {
  return {
    cartList: state.reducerMyCart.cartList,
    productDetails: state.reducerRedeem.productDetails,
    finalCartArray: state.reducerRedeem.finalCartArray,
    cartItemCount: state.reducerRedeem.cartItemCount,
    merchantDetailsResponse: state.reducerDashboard.merchantDetailsResponse,
    uploadingStatus: state.reducerBillUploadProgress.uploadingStatus
  };
};

class MyCartContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = async () => {
    if (getStorageItem("user")) {
      const bodyFormData = new URLSearchParams();
      bodyFormData.append("customer_id", JSON.parse(getStorageItem("user")).id);
      bodyFormData.append("merchant_id", apiData.merchant_id);
      bodyFormData.append("platform", apiData.platform);

      await this.props.getCartItemCount(bodyFormData);

      const { changeActiveTab } = this.props;
      changeActiveTab(2);
    }
  };

  render() {
    const {
      cartList,
      getCartList,
      productDetails,
      addToCart,
      finalCartArray,
      addCart,
      getCartItemCount,
      cartItemCount,
      merchantDetailsResponse,
      merchantDetails,
      showLoader,
      logoutUser,
      clearCartList,
    } = this.props;
    const MyCartProps = {
      cartList,
      getCartList,
      productDetails,
      addToCart,
      finalCartArray,
      addCart,
      getCartItemCount,
      cartItemCount,
      merchantDetailsResponse,
      merchantDetails,
      showLoader,
      logoutUser,
      clearCartList,
    };
    return (
      <Fragment>
        <HeaderCart
          cartItemCount={this.props.cartItemCount}
          merchantDetails={this.props.merchantDetails}
          merchantDetailsResponse={this.props.merchantDetailsResponse}
        />
        <AlertMessage />
        <MyRoute billUploadStatus={this.props.uploadingStatus}>
            <MyCart {...MyCartProps} />
        </MyRoute>
        <Grid item>
					<Footer />
				</Grid>
      </Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MyCartContainer));
