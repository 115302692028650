import MomentUtils from '@date-io/moment';
import { Button, FormControl, InputBase, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { withStyles } from '@material-ui/styles';
import moment from 'moment';
import React, { Component } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { showLoader, logoutUser } from '../../../../App/redux/action';
import calender from '../../../../assets/icons/book_visit/calender.png';
import disable_checkbox from '../../../../assets/icons/book_visit/disable-checkbox.svg';
import selected from '../../../../assets/icons/book_visit/selected.svg';
import mall_logo from "../../../../assets/icons/splash/mall_logo_bottom.png";
import down_arrow from '../../../../assets/icons/submit_bill/toggle_closed_btn_icon.svg';
import { openAlertBox } from '../../../../common/AlertMessage/AlertMessage';
import { stockAPI } from '../../../../common/axiosInstance';
import { apiData } from '../../../../common/common-types';
import { lightGrey } from '../../../../components/UI/Theme';
import { styles } from './BookVisitFormStyles';
import { encrypt } from '../../../../crypto-helper';

const mapDispatchToProps = {
	showLoader,
	logoutUser
};

const CustomInput = withStyles((theme) => ({
	root: {
		width: '96%',
		position: 'relative',
		fontSize: 20,
		padding: '0px 24px 0px 13px',
		textAlign: 'left',
		[theme.breakpoints.down(641)]: {
			fontSize:  16,
			padding: '0px 24px 0px 13px'
		},
		[theme.breakpoints.down(481)]: {
			fontSize:  14,
			padding: '0px 24px 0px 13px'
		},
		[theme.breakpoints.down(421)]: {
			fontSize:  14,
			padding: '0px 24px 0px 13px'
		},
		[theme.breakpoints.down(401)]: {
			fontSize:  13,
			padding: '0px 24px 0px 13px'
		},
		[theme.breakpoints.down(381)]: {
			fontSize:  12,
			padding: '0px 24px 0px 13px'
		},
		[theme.breakpoints.down(361)]: {
			fontSize:  12,
			padding: '0px 24px 0px 13px'
		},
		[theme.breakpoints.down(321)]: {
			fontSize:  10,
			padding: '0px 24px 0px 13px'
		},
	}
}))(InputBase);

const CustomTextField = withStyles((theme) => ({
	root: {
		width: '96%',
		position: 'relative',
		textAlign: 'left'
	}
}))(TextField);


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: '50%'
		}
	}
};

class BookVisitForm extends Component {
	state = {
		fname: '',
		helperTextFname: '',
		fnameError: false,

		lname: '',
		helperTextLname: '',
		lnameError: false,

		mobileNumber: '',
		helperTextMobile: '',
		mobileError: false,

		noofpeople: "",
		helperTextNoofPeople: "",
		nopError: false,

		timeslots: [],
		timeslot: 0,
		helperTextTimeSlot: "",
		timeslotError: false,

		dov: null,
		helperDVError: "",
		dovEroor: false,

		isInMall: false,
	};

	componentDidMount() {
		this.props.showLoader(true);
		if (this.props.user) {
			const data = {
				merchant_id: apiData.merchant_id,
				mobile_number:  this.props.user.mobile_number
			  }
			  let encryptedPayload ={
				_k: encrypt(JSON.stringify(data))
			  }
			stockAPI(encryptedPayload, 'POST', '/checkCustomerData').then((response) => {
				if (response.customer === "") {
					this.props.logoutUser();
				} else {
					this.setState({
						mobileNumber: this.props.user.mobile_number,
						fname: response.customer ? response.customer.first_name : "",
						lname: response.customer ? response.customer.last_name : "",
					});
				}
				this.props.showLoader(false);
			});
		} else {
			this.props.showLoader(false);
		}
	}

	submitButton = (e) => {
		e.preventDefault();
		if (
			this.state.fname && this.state.lname && this.state.mobileNumber && this.state.noofpeople &&
			this.state.helperTextFname === '' &&
			this.state.helperTextLname === '' &&
			this.state.helperTextMobile === '' &&
			this.state.helperTextNoofPeople === '' &&
			this.state.helperTextTimeSlot === '' &&
			(this.state.isInMall ? true : this.state.dov) &&
			(this.state.isInMall ? true : this.state.timeslot)
		) {
			const formData = new URLSearchParams();
			formData.append('mobile_number', this.state.mobileNumber);
			formData.append('time', this.state.isInMall ? moment(new Date()).format("HH:mm") : "");
			formData.append('total_visitor', this.state.noofpeople);
			formData.append('first_name', this.state.fname);
			formData.append('last_name', this.state.lname);
			formData.append(
				'visit',
				this.state.isInMall ? moment(new Date()).format("YYYY-MM-DD") :
				`${this.state.dov.getFullYear()}-${this.state.dov.getMonth() + 1}-${this.state.dov.getDate()}`
			);
			formData.append('merchant_id', apiData.merchant_id);
			formData.append('merchant_location_id', 3);
			formData.append('slot_id', this.state.isInMall ? "" : this.state.timeslot);

			stockAPI(formData, 'POST', '/bookScheduleVisit', null, null, null, true).then((response1) => {
				if (response1.response) {
					const { response } = response1;
					if (response.is_booked === "yes") {
						if (response.payment_status === "Booked") {
							openAlertBox(response1.statusDescription, "error", 5000);
						} else {
							this.props.setPage(3);
							this.props.setData({
								isInMall: this.state.isInMall,
								...response,
								mobile_number: this.state.mobileNumber,
							});
						}
					} else {
						openAlertBox(response1.statusDescription, "error", 5000);
					}
				}
			});
		}
	};

	handleFname = (e) => {
		const re = /^[a-zA-Z]+$/;
		const value = e.target.value;
		let errorMessage = '';
		let errorMessageStatus = false;

		if (value.length === 0) {
			errorMessage = 'First name is required.';
			errorMessageStatus = true;
		} else {
			if (value.length > 30) {
				return false;
			}
			if (re.test(value.trim())) {
				e.preventDefault();
				errorMessage = '';
				errorMessageStatus = false;
			} else {
				errorMessage = 'Please enter valid name.';
				errorMessageStatus = true;
			}
		}

		this.setState({
			fname: value.trim(),
			helperTextFname: errorMessage,
			fnameError: errorMessageStatus
		});
	};

	handleLname = (e) => {
		const re = /^([a-zA-Z]+)?([[a-zA-Z'-]{0,1})?[a-zA-Z]+$/;
		const value = e.target.value;
		let errorMessage = '';
		let errorMessageStatus = false;

		if (value.length === 0) {
			errorMessage = 'Last name is required.';
			errorMessageStatus = true;
		} else {
			if (value.length > 30) {
				return false;
			}
			if (re.test(value.trim())) {
				e.preventDefault();
				errorMessage = '';
				errorMessageStatus = false;
			} else {
				errorMessage = 'Please enter valid name.';
				errorMessageStatus = true;
			}
		}
		this.setState({
			lname: value.trim(),
			helperTextLname: errorMessage,
			lnameError: errorMessageStatus
		});
	};

	handleMobileChange = (event) => {
		const re = /(^[4-9][0-9\s\b]+$)/;
		if (
			event.target.value !== '3' &&
			event.target.value !== '2' &&
			event.target.value !== '1' &&
			event.target.value !== '0'
		) {
			if (event.target.value === '' || re.test(event.target.value)) {
				this.setState({ helperTextMobile: '', mobileError: false });
			}
		} else {
			this.setState({
				helperTextMobile: 'Please enter a valid 10 digit mobile number.',
				mobileError: true
			});
		}

		if (re.test(event.target.value) && (event.target.value.length === 10)) {
			this.setState({
				helperTextMobile: '',
				mobileError: false
			});
		} else {
			this.setState({
				helperTextMobile: 'Please enter a valid 10 digit mobile number.',
				mobileError: true
			});
		}

		this.setState({ mobileNumber: event.target.value });
	};

	handleMobileChange1 = (event) => {
		const re = /(^[4-9][0-9\s\b]+$)/;
		this.setState({ mobileNumber: event.target.value });
		if (re.test(event.target.value) && (event.target.value.length === 10)) {
			this.setState({
				helperTextMobile: '',
				mobileError: false
			});
			// if (!this.props.user || (this.props.user && event.target.value != this.props.user.mobile_number)) {
				const formData = {
					'mobile_number': event.target.value,
					'merchant_id': apiData.merchant_id

				}
				let encryptedPayload ={
					_k: encrypt(JSON.stringify(formData))
				  }
				
				stockAPI(encryptedPayload, 'POST', '/checkCustomerData').then((response) => {
					this.setState({
						fname: response.customer ? response.customer.first_name : "",
						lname: response.customer ? response.customer.last_name : "",
						isInMall: false,
						noofpeople: "",
						timeslot: "",
						dov: null,
					});
				});
			// }
		} else {
			this.setState({
				helperTextMobile: 'Please enter a valid 10 digit mobile number.',
				mobileError: true
			});
		}
	};

	noofpeopleChange = (e) => {
		const valu = e.target.value;
	 	if (!valu) {
			this.setState({
				noofpeople: valu,
				helperTextNoofPeople: 'Please enter only number value.',
				nopError: true
			});
		} else if (valu.substring(valu.length, 1) === ".") {	
			this.setState({
				noofpeople: this.state.noofpeople,
				helperTextNoofPeople: "",
				nopError: false,
			});
		} else if (!Number(valu) || (valu && valu.length > 2)) {
			this.setState({
				noofpeople: this.state.noofpeople,
				helperTextNoofPeople: "Please enter only number value.",
				nopError: true
			});
		} else if (valu === "0" || valu === "00") {
			this.setState({
				noofpeople: valu,
				helperTextNoofPeople: "No. of People should not be zero.",
				nopError: true
			})
		} else {
			this.setState({
				noofpeople: valu,
				helperTextNoofPeople: "",
				nopError: false
		 	});
		}
	};

	handleDateChange = (date) => {
		this.setState({
			dov: date._d,
			dovEroor: date ? false : true,
			helperDVError: date ? "" : "Please select visit date",
		}, () => {
			if (date._d && this.state.noofpeople && this.state.noofpeople !== "0") {
				this.getSlots();
			}
		});
	};

	getSlots = () => {
		const formData = new URLSearchParams();
		formData.append('total_visitor', this.state.noofpeople);
		formData.append(
			'visit',
			this.state.dov
				? `${this.state.dov.getFullYear()}-${this.state.dov.getMonth() + 1}-${this.state.dov.getDate()}`
				: ''
		);
		formData.append('merchant_id', apiData.merchant_id);
		formData.append('merchant_location_id', 3);

		stockAPI(formData, 'POST', '/bookAvailableSlotList').then((res) => {
			if (res.available_slot && res.available_slot.length > 0) {
				this.setState({
					timeslots: res.available_slot,
				});
			} else {
				openAlertBox("Time slots are not available", "error", 360000);
			}
		});
	};

	handleSlotChange = (e) => {
		const value = e.target.value;
		let errorMessage = '';
		let errorMessageStatus = false;

		if (!value) {
			errorMessage = 'Please select time slot.';
			errorMessageStatus = true;
		}
		this.setState({
			timeslot: value,
			helperTextTimeSlot: errorMessage,
			timeslotError: errorMessageStatus
		});
	};

	render() {
		const { classes } = this.props;

		const isSubmitEnabled = !(
			this.state.fname && this.state.lname && this.state.mobileNumber && this.state.noofpeople &&
			this.state.helperTextNoofPeople === "" &&
			this.state.helperTextLname === "" &&
			this.state.helperTextFname === "" &&
			this.state.helperTextMobile === "" &&
			(!this.state.isInMall ? this.state.timeslot && this.state.helperTextTimeSlot === "" &&
			this.state.helperDVError === "" && this.state.dov : true)
		);

		const today = new Date();
		const maxDate = new Date(moment(new Date()).add(7, "days").format("YYYY-MM-DD"));

		return (
			<MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment} locale={'en'}>
				<div className={classes.pageContainer}>
				
					<div className={classes.root}>
						

						<ValidatorForm
							ref="form"
							onSubmit={this.submitButton}
							onError={(errors) => console.log(errors)}
							className={classes.formControl1}
						>
								
								<div className={classes.formContent}>
									
								<div>					
									<div style={{ textAlign: "center" }}>
										<img alt="mall" className={classes.icons} src={mall_logo}/>
									</div>
									<Typography variant="h1" className={classes.heading}>
									Book Your Visit to Oberoi Mall
									</Typography>

									<Typography variant="h3" className={classes.subHeading} style={{ marginBottom: 10 }}>
									Please help us with the following details
									</Typography>
								</div>
								<Grid container spacing={1}>
									<Grid item xs={3} sm={3}>
										<FormControl
										variant="outlined"
										className={classes.selDrop}
										disabled
									>
										<InputLabel style={{ marginTop: 0 }} id="demo-simple-select-outlined-label">
											+91
										</InputLabel>
										<img
											className={classes.down_icon1}
											src={down_arrow}
											alt=""
											width="10"
											height="6"
										/>
									</FormControl>
									</Grid>
									<Grid item xs={9} sm={9}>
										<TextValidator
											InputProps={{
												classes: {
													underline: classes.cssUnderline,
													input: classes.input
												}
											}}
											InputLabelProps={{
												classes: {
													root: classes.cssLabel,
													focused: 'focused',
													shrink: 'shrink'
												}
											}}
											style={{ paddingTop: 3 }}
											className={classes.fieldSet}
											onChange={this.handleMobileChange}
										
											value={this.state.mobileNumber}
											validators={[ 'required' ]}
											errorMessages={[ 'This field is required.', 'Mobile number is required.' ]}
											helperText={this.state.helperTextMobile}
											error={this.state.mobileError}
											inputProps={{
												onBlur: this.handleMobileChange1,
												maxLength: 10,
												autoComplete: 'off',
												type: 'tel',
												placeholder: "Enter your mobile number",
											}}
										/>
									</Grid>
								</Grid>
								<div className={classes.itemContainer + ' ' + classes.padd_right}>
									<TextValidator
										InputProps={{
											classes: {
												underline: classes.cssUnderline,
												input: classes.input
											}
										}}
										InputLabelProps={{
											classes: {
												root: classes.cssLabel,
												focused: 'focused',
												shrink: 'shrink'
											}
										}}
										className={classes.fieldSet}
										label="First Name"
										onChange={this.handleFname}
										value={this.state.fname}
										validators={[ 'required' ]}
										errorMessages={[ 'This field is required.', 'First name is required.' ]}
										helperText={this.state.helperTextFname}
										error={this.state.fnameError}
										inputProps={{
											maxLength: 12,
											autoComplete: 'off'
										}}
									/>
								</div>
								<div className={classes.itemContainer + ' ' + classes.padd_left}>
									<TextValidator
										InputProps={{
											classes: {
												underline: classes.cssUnderline,
												input: classes.input
											}
										}}
										InputLabelProps={{
											classes: {
												root: classes.cssLabel,
												focused: 'focused',
												shrink: 'shrink'
											}
										}}
										className={classes.fieldSet}
										label="Last Name"
										onChange={this.handleLname}
										value={this.state.lname}
										validators={[ 'required' ]}
										errorMessages={[ 'This field is required.', 'Last Name is required.' ]}
										helperText={this.state.helperTextLname}
										error={this.state.lnameError}
										inputProps={{
											maxLength: 12,
											autoComplete: 'off'
										}}
									/>
								</div>
								<div>
									<TextValidator
										InputProps={{
											classes: {
												underline: classes.cssUnderline,
												input: classes.input
											},
										}}
										InputLabelProps={{
											classes: {
												root: classes.cssLabel,
												focused: 'focused',
												shrink: 'shrink'
											}
										}}
										className={classes.fieldSet1}
										label="No. of People"
										onChange={this.noofpeopleChange}
										value={this.state.noofpeople}
										validators={[ 'required' ]}
										maxlength={2}
										errorMessages={[ 'This field is required.', 'No. of People is required.' ]}
										helperText={this.state.helperTextNoofPeople}
										error={this.state.nopError}
										inputProps={{
											type: "tel",
											autoComplete: 'off',
											inputMode: 'numeric',
											pattern: "[0-9]*",
										}}
									/>
								</div>
								<Grid container>
									<Grid xs={1} sm={1} item onClick={() => {
										this.setState({
											isInMall: !this.state.isInMall,
											dov: null,
											timeslot: 0,
											helperDVError: "",
											dovEroor: false,
											helperTextTimeSlot: "",
											timeslotError: false,
										});
									}}>
										<img alt="mall" className={classes.checkbox} src={this.state.isInMall ? selected : disable_checkbox}/>
									</Grid>
									<Grid xs={11} sm={11} item>
										<div className={classes.checkboxText} style={{ color: lightGrey }}>I am at the mall.</div>
									</Grid>
								</Grid>	
								{!this.state.isInMall
								&& <div>
									<DatePicker
										autoOk
										format="DD / MM / YYYY"
										value={this.state.dov}
										onChange={this.handleDateChange}
										minDate={new Date()}
										maxDate={maxDate}
										TextFieldComponent={(props) => {
											return (
												<CustomTextField
													{...props}
													id="dov"
													inputProps={{
														style: {
															padding: 0
														},
														autoComplete: 'off'
													}}
													className={classes.fieldSet}
													InputProps={{ disableUnderline: true,  endAdornment: <img className={classes.calenderIcon} src={calender}/> }}
													InputLabelProps={{
														classes: {
															root: classes.cssLabel,
															focused: 'focused',
															shrink: 'shrink'
														}
													}}
													label="Select Date of Visit"
												/>
											);
										}}
									/>
								</div>}
								<span className={classes.errorMessage}>{this.state.helperDVError}</span>
								{!this.state.isInMall
								&& <div className={classes.pageItems + ' ' + classes.select_box}>
									<FormControl fullWidth className={classes.formControl}>
										<label className={classes.tx_label}>
										Time Slot for Visit
										</label>
										<Select
											value={this.state.timeslot}
											onChange={(e) => this.handleSlotChange(e)}
											input={<CustomInput />}
											IconComponent={() => (
												<img className={classes.down_icon} src={down_arrow} alt="" width="10" height="6" />
											)}
											MenuProps={MenuProps}
											disabled={this.state.timeslots.length < 1}
										>
											<MenuItem disabled value={0}>
												Select time slot
											</MenuItem>
											{this.state.timeslots.length > 0 &&
												this.state.timeslots.map((slot) => (
													<MenuItem key={slot.slot_id} value={slot.slot_id}>
														{slot.slot_name}
													</MenuItem>
												))}
										</Select>
									</FormControl>
								</div>}
								<span className={classes.errorMessageselect}>{this.state.helperTextTimeSlot}</span>
								
							</div>
							<div className={classes.btnContainer}>
								<Button
									type="submit"
									disableRipple
									disabled={isSubmitEnabled}
									className={classes.submitBtn}
								>
									SUBMIT
								</Button>
							</div>
						</ValidatorForm>
					</div>
				</div>
			</MuiPickersUtilsProvider>
		);
	}
}

export default connect(null, mapDispatchToProps)(withRouter(withStyles(styles, { withTheme: true })(BookVisitForm)));

