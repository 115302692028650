import React, { Component, Fragment } from 'react';
import Header from '../../../../../components/Layout/Header/HeaderSubmitBill';
import { headerScreenLabels, apiData } from '../../../../../common/common-types';
import AlertMessage, { openAlertBox } from '../../../../../common/AlertMessage/AlertMessage';
import MyRoute from '../../../../../hoc/MyRoute';
import { styles } from './FeedbackFormStyles';
import { withStyles } from '@material-ui/styles';
import { stockAPI } from '../../../../../common/axiosInstance';
import { getStorageItem } from '../../../../../common/localStorage';
import { connect } from 'react-redux';
import { changeActiveTab, logoutUser, showLoader } from '../../../../../App/redux/action';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Button, FormControl, Select, InputBase, MenuItem } from '@material-ui/core';
import down_arrow from '../../../../../assets/icons/submit_bill/toggle_closed_btn_icon.svg';

const CustomInput = withStyles((theme) => ({
	root: {
		width: '100%',
		position: 'relative',
		fontSize: '20px',
		padding: '10px 24px 10px 11px',
		textAlign: 'left',
		backgroundColor: '#fff',
		border: 'solid 1px #e4e4e4',
		borderRadius: '5px',
		[theme.breakpoints.down('sm')]: {
			padding: '8px 24px 8px 11px'
		},
		[theme.breakpoints.down('xs')]: {
			padding: '6px 24px 6px 11px'
		},
		[theme.breakpoints.down('641')]: {
			fontSize: '16px'
		},
		[theme.breakpoints.down('481')]: {
			fontSize: '14px'
		},
		[theme.breakpoints.down('381')]: {
			fontSize: '12px'
		},
		[theme.breakpoints.down('321')]: {
			fontSize: '10px'
		}
	}
}))(InputBase);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: '50%'
		}
	}
};

const mapStateToProps = (state) => {
	return {
		uploadingStatus: state.reducerBillUploadProgress.uploadingStatus
	};
};

const mapDispatchToProps = {
	changeActiveTab,
	logoutUser,
	showLoader
};

const subjectList = ['General', 'Bill Rejections', 'Points Redemptions', 'Points Credited', 'Other'];

class FeedbackForm extends Component {
	state = {
		fname: '',
		lname: '',
		mobileNumber: '',
		email: '',
		subjectSelected: 'General',
		message: '',
		isDisableBtn:false
	};

	formatPhoneNumber = (phoneNumberString) => {
		var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
		var match = cleaned.match(/^(\d{4})(\d{3})(\d{3})$/);
		if (match) {
		  return match[1] + " " + match[2] + " " + match[3];
		}
		return null;
	  };

	componentDidMount() {
		// this.props.showLoader(true);
		if (getStorageItem('user')) {
			const formData = new URLSearchParams();
			formData.append('platform', apiData.platform);
			formData.append('userId', JSON.parse(getStorageItem('user')).id);
			formData.append('merchant_id', apiData.merchant_id);
			formData.append("customer_id",JSON.parse(getStorageItem("user")).id);

			stockAPI(formData, 'POST', '/getMerchantDetails').then((response) => {
				// this.props.showLoader(false);
				if (response.merchant_list && response.merchant_list.added[1].customerDetails) {
					this.setState({
						fname: response.merchant_list.added[1].customerDetails.first_name,
						lname: response.merchant_list.added[1].customerDetails.last_name,
						mobileNumber: this.formatPhoneNumber(response.merchant_list.added[1].customerDetails.mobile_number),
						email: response.merchant_list.added[1].customerDetails.email_address
					});
				} else {
					openAlertBox('Oops something went wrong!', 'error');
				}
			});
		} else {
			this.props.showLoader(false);
			this.props.logoutUser();
		}
	}

	handleCancelBtn = (e) => {
		e.preventDefault();
		this.props.history.push('/more');
	};

	handleChange = (e) => {
		if( e.target.value!==" "){
		this.setState({
			message: e.target.value
		});
	}
	};
	validateForm = () => {
		let isValid = true;
		const regex = /^[a-zA-Z0-9_ .,\-;&!"'()+?\s]*$/;
		// Check if the input matches the regular expression
		if (!regex.test(this?.state?.message)) {
		isValid = false;
		openAlertBox("Only alphanumeric, blank space, special characters (''_ . , - ; & ! ? \"\" +) are allowed.", "error");
		}
		return isValid;
	  }

	submitButton = (e) => {
		if(!this.validateForm()){
			return
		}
		e.preventDefault();
		this.setState({
			...this?.state,
			isDisableBtn:true
		});
		if (getStorageItem('user')) {
			const formData = new URLSearchParams();
			formData.append('platform', apiData.platform);
			formData.append('customer_id', JSON.parse(getStorageItem('user')).id);
			formData.append('merchant_id', apiData.merchant_id);
			formData.append('app_info', '');
			formData.append('subject', this.state.subjectSelected);
			formData.append('feedback_message', this.state.message);

			// axios.post(apiData.baseUrl +'/submitFeedback', formData).then(response => {
			stockAPI(formData, 'POST', '/submitFeedback', null, null, null, true).then(response => {
				if (response.statusCode && response.statusCode === 100) {
					if (response.statusDescription) {
						openAlertBox(response.statusDescription, 'success');
						this.setState({
							subjectSelected: 'General',
							message: '',
							isDisableBtn:false
						});
					}
				} else if(response?.statusCode === 102){
					openAlertBox(response?.statusDescription || 'Oops something went wrong!', 'error');	
					this.setState({
						...this.state,
						isDisableBtn:false
					});
				}else {
					openAlertBox('Oops something went wrong!', 'error');	
					this.setState({
						...this.state,
						isDisableBtn:false
					});
				}
			}).catch(err => {
				openAlertBox('Oops something went wrong!', 'error');
				this.setState({
					...this.state,
					isDisableBtn:false
				});
			});
		} else {
			this.props.logoutUser();
		}
	};

	handleSubjectChange = (e) => {
		this.setState({
			subjectSelected: e.target.value
		});
	};

	render() {
		const { classes } = this.props;
		return (
			<Fragment>
				<Header screenLabel={headerScreenLabels.feedback} />
				<AlertMessage />
				<MyRoute fullHeight={true} billUploadStatus={this.props.uploadingStatus}>
					<div className={classes.pageContainer}>
						<div className={classes.root}>
							<ValidatorForm
								ref="form"
								onSubmit={this.submitButton}
								onError={(errors) => console.log(errors)}
								className={classes.formControl}
							>
								<div className={classes.itemContainer + ' ' + classes.padd_right}>
									<TextValidator
										InputProps={{
											classes: {
												underline: classes.cssUnderline,
												input: classes.input
											}
										}}
										InputLabelProps={{
											classes: {
												root: classes.cssLabel,
												focused: 'focused',
												shrink: 'shrink'
											}
										}}
										className={classes.fieldSet}
										label="First Name"
										value={this.state.fname}
										FormHelperTextProps={{ style: { marginLeft: '10px' } }}
										disabled
									/>
								</div>
								<div className={classes.itemContainer + ' ' + classes.padd_left}>
									<TextValidator
										InputProps={{
											classes: {
												underline: classes.cssUnderline,
												input: classes.input
											}
										}}
										InputLabelProps={{
											classes: {
												root: classes.cssLabel,
												focused: 'focused',
												shrink: 'shrink'
											}
										}}
										className={classes.fieldSet}
										label="Last Name"
										value={this.state.lname}
										FormHelperTextProps={{ style: { marginLeft: '10px' } }}
										disabled
									/>
								</div>
								<div className={classes.itemContainer + ' ' + classes.padd_right}>
									<TextValidator
										InputProps={{
											classes: {
												underline: classes.cssUnderline,
												input: classes.input
											}
										}}
										InputLabelProps={{
											classes: {
												root: classes.cssLabel,
												focused: 'focused',
												shrink: 'shrink'
											}
										}}
										className={classes.fieldSet}
										disabled
										label="Mobile Number"
										value={'+91 ' + this.state.mobileNumber}
									/>
								</div>
								<div className={classes.itemContainer + ' ' + classes.padd_left}>
									<TextValidator
										InputProps={{
											classes: {
												underline: classes.cssUnderline,
												input: classes.input
											}
										}}
										InputLabelProps={{
											classes: {
												root: classes.cssLabel,
												focused: 'focused',
												shrink: 'shrink'
											}
										}}
										className={classes.fieldSet}
										label="Email Address"
										value={this.state.email}
										disabled
									/>
								</div>
								<div className={classes.pageItems}>
									<FormControl fullWidth className={classes.formControl}>
										<Select
											native
											name="points"
											labelId="points"
											id="points"
											value={this.state.subjectSelected}
											onChange={this.handleSubjectChange}
											input={<CustomInput />}
											IconComponent={() => (
												<img
													className={classes.down_icon}
													src={down_arrow}
													alt=""
													width="10"
													height="6"
												/>
											)}
											MenuProps={MenuProps}
										>
											<option className={classes.menuItem} disabled value={0}>
												Select Subject
											</option>
											{subjectList.map((sub) => (
												<option
													className={classes.menuItem}
													key={sub}
													value={sub}
												>
													{sub}
												</option>
											))}
										</Select>
									</FormControl>
								</div>
								<div className={classes.pageItems}>
									<textarea
										className={classes.textMessage}
										value={this.state.message}
										onChange={this.handleChange}
										rows={9}
										placeholder="Message"
										maxLength={1024}
									/>
								</div>
								<div style={{ marginTop: '21px' }}>
									<div className={classes.itemContainer + ' ' + classes.padd_right}>
										<Button
											type="submit"
											disableRipple
											disabled={this.state.message === '' || this?.state?.isDisableBtn}
											className={classes.submitBtn}
										>
											SUBMIT
										</Button>
									</div>
									<div className={classes.itemContainer + ' ' + classes.padd_left}>
										<Button
											onClick={this.handleCancelBtn}
											disableRipple
											className={classes.cancelBtn}
										>
											CANCEL
										</Button>
									</div>
								</div>
							</ValidatorForm>
						</div>
					</div>
				</MyRoute>
			</Fragment>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(FeedbackForm));
