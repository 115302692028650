import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import classnames from "classnames";
import { Grid, Button, Dialog, Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { withStyles } from "@material-ui/styles";
import axios from "axios";
import { stockAPI } from "../../../../common/axiosInstance";
import BillDetailDialog from "../../../../components/BillDetailsDialog/BillDetailsDialog";
import Slide from "@material-ui/core/Slide";
import BillImage from "../../../../components/BillImage/BillImage";
import LongBill from "../../../../components/LongBill/LongBill";
import { openAlertBox } from "../../../../common/AlertMessage/AlertMessage";
import { apiData } from "../../../../common/common-types";
import { getStorageItem } from "../../../../common/localStorage";
import { styles } from "./SubmitBillStyles";
import SingleClickImage from "../../../../components/SingleClickImage/SingleClickImage";
import UploadBoxWithIcon from "../../../../components/UploadBox/UploadBoxWithIcon";
import platform from "platform";
import PlusButton from "../../../../components/PlusButton/PlusButton";
import UnsavedChangesWarning from "./UnsavedChangesWarning/UnsavedChangesWarning";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const errorMessageImageFile = "Image file format is wrong";
const multiCancel = "Request multi is canceled.";
const singleCancel = "Request is canceled.";
const requiredMessage = "This field is required.";
const numericValueMessage = "Enter numeric value.";
const genericBillFailedMessage = "Bill submit failed.";

class SubmitBill extends Component {
  fileObj = [];
  fileArray = [];
  cancel = null;

  cancelMulti = null;
  state = {
    tabValue: this.props.isShortBill
      ? this.props.history.location.state
        ? this.props.history.location.state.tab
        : 0
      : 1,
    singleClickImages: [],
    doneBtnStatus: false,
    singleBillDetailPopup: false,
    singleBillImagePopup: false,
    singleBillDetailSelected: null,
    singleBillImageSelected: null,
    removeImageId: "",
    singleBillPDFPopup: false,
    singleBillPDFSelected: null,

    locations: [],
    brands: [],
    multiClickImages: [],
    location: 0,
    brand: 0,
    billNumber: "",
    billDate: new Date(),
    billAmount: "",
    submitBtnStatus: true,
    multiFormSubmitted: false,
    multiClickImagesSize: 0,
    multiImagesUploadPercent: 0,

    billAmountValidation: {
      error: false,
      touched: false,
      message: "",
    },
    billNumberValidation: {
      error: false,
      touched: false,
      message: "",
    },

    locationValidation: {
      error: false,
      touched: false,
      message: "",
    },

    brandValidation: {
      error: false,
      touched: false,
      message: "",
    },

    moreText: false,

    no_days: 0,
    show: true,
    scrollPos: 0,
    shouldBlockNavigation: false,
    isDirty: false,
  };

  resetState = this.state;

  componentDidMount() {
    window.addEventListener("scroll", this.detectPageScroll);
    if (this.state.tabValue === 1) {
      this.getLocations();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.detectPageScroll);
    if (
      this.props.history.location.state &&
      this.props.history.location.state.hasOwnProperty("tab")
    ) {
      delete this.props.history.location.state.tab;
    }
  }

  //get locations
  getLocations = () => {
    const data = new FormData();
    data.append("merchant_id", apiData.merchant_id);
    data.append("platform", apiData.platform);
    stockAPI(data, "POST", "/getStoreLocations")
      .then((response) => {
        if (response) {
          this.setState(
            {
              locations: response.locations,
              location:
                response.locations && response.locations.length === 1
                  ? response.locations[0].id
                  : 0,
              no_days:
                response.locations && response.locations.length === 1
                  ? response.locations[0].bill_date_submission_days
                  : 0,
            },
            () => {
              if (this.state.location !== 0) {
                this.getBrands(this.state.location);
              }
            }
          );
        } else {
          openAlertBox("Oops something went wrong!", "error");
        }
      })
      .catch((err) => {
        openAlertBox(err && err.message, "error");
      });
  };

  //get brands
  getBrands = (location_id) => {
    if (getStorageItem("user") && location_id !== 0) {
      const data = new FormData();
      data.append("customer_id", JSON.parse(getStorageItem("user")).id);
      data.append("merchant_id", apiData.merchant_id);
      data.append("platform", apiData.platform);
      data.append("location_id", location_id);

      stockAPI(data, "POST", "/getBrandName")
        .then((response) => {
          if (response) {
            this.setState(
              {
                brands: response,
                brand: response && response.length === 1 ? response[0].id : 0,
              },
              () => {
                this.updateValidationStatus();
              }
            );
          } else {
            openAlertBox("Oops something went wrong!", "error");
          }
        })
        .catch((err) => {
          openAlertBox(err && err.message, "error");
        });
    } else {
      this.props.logoutUser();
    }
  };

  handleTabValueChange = (event, newValue) => {
    if (this.state.tabValue === newValue) {
      event.preventDefault();
      return false;
    }
    if (this.state.singleClickImages.length > 0) {
      this.setState({
        isDirty: true,
      });
    } else if (
      this.state.brand ||
      this.state.billAmount ||
      this.state.billNumber ||
      this.state.multiClickImages.length > 0
    ) {
      this.setState({
        isDirty: true,
      });
    } else {
      this.setState(
        {
          tabValue: newValue,
        },
        () => {
          if (this.state.tabValue === 1) {
            this.getLocations();
          }
        }
      );
    }
  };

  handleYes = (event) => {
    if (this.props.isShortBill && this.state.tabValue === 0) {
      this.fileObj = [];
      this.fileArray = [];
      this.cancel = null;
      this.cancelMulti = null;
      this.setState(
        {
          ...this.resetState,
          tabValue: 1,
          isDirty: false,
        },
        () => {
          if (this.state.tabValue === 1) {
            this.getLocations();
          }
        }
      );
    } else {
      this.setState({
        ...this.resetState,
        tabValue: 0,
        isDirty: false,
      });
    }
  };

  handleNo = (event) => {
    event.preventDefault();
    this.setState({
      isDirty: false,
    });
  };
  //single click bill image functions
  //generating unique id's for single click
  //image deletion
  generateId = () => {
    return "_" + Math.random().toString(36).substr(2, 9);
  };

  //onChange function call when file selected
  //for single click image bill
  selectImage = async (e) => {
    const files = e.target.files;

    e.persist();

    const filesUploaded = this.state.singleClickImages.length;
    let allowUpload = 0;
    allowUpload = 5 - filesUploaded;
    if (allowUpload > files.length) {
      allowUpload = files.length;
    }

    if (this.state.singleClickImages.length < 5) {
      if (files && files.length > 0) {
        this.fileObj.push(files);
        for (let i = 0; i < allowUpload; i++) {
          if (
            files[i] &&
            (files[i].type === "image/png" ||
              files[i].type === "image/jpeg" ||
              files[i].type === "image/jpg" ||
              files[i].type === "application/pdf")
          ) {
            if (files[i].size <= 10485760) {
              let uri = null;
              if (platform.product === "iPhone") {
                uri = await URL.createObjectURL(files[i]);
              } else {
                uri = await this.getUriForFile(files[i]);
              }
              if (uri) {
                const obj = {
                  file: files[i],
                  uri: uri,
                  my_id: this.generateId(),
                  pdf: files[i].type === "application/pdf",
                  percent: 0,
                };

                await this.setState((prevState) => ({
                  singleClickImages: [...prevState.singleClickImages, obj],
                  shouldBlockNavigation: true,
                }));
              } else {
                this.fileObj.splice(i, 1);
                openAlertBox("Error reading the file.", "error");
              }
            } else {
              this.fileObj.splice(i, 1);
              openAlertBox("Cannot upload! Image size is large.", "error");
            }
          } else {
            this.fileObj.splice(i, 1);
            openAlertBox(errorMessageImageFile, "error");
          }
        }
      }
    } else {
      openAlertBox("Already selected 5 images", "error");
    }
    e.target.value = "";
  };

  getUriForFile = async (file) => {
    const uri = await file
      .slice(0, 1)
      .arrayBuffer()
      .then(() => {
        return true;
      })
      .catch((err) => {
        return false;
      });

    if (uri) {
      return URL.createObjectURL(file);
    } else {
      return false;
    }
  };

  //upload images in single click bill
  uploadImage = async () => {
    const { singleClickImages } = this.state;
    for (let i = 0; i < singleClickImages.length; i++) {
      await this.setState({
        doneBtnStatus: true,
      });
      if (singleClickImages[i].status) {
        await this.setState({
          doneBtnStatus: false,
        });
        continue;
      } else {
        const file = singleClickImages[i].file;
        if (getStorageItem("user")) {
          let data = new FormData();
          // data.append('mobile_number', JSON.parse(getStorageItem('user')).mobile_number);
          data.append("customer_id", JSON.parse(getStorageItem("user")).id);
          data.append("merchant_id", apiData.merchant_id);
          data.append("platform", apiData.platform);
          data.append("bill_upload_type", 1);
          data.append("bill_image[]", file);
          let CancelToken = axios.CancelToken;

          const cancelToken = new CancelToken((c) => {
            this.cancel = c;
          });

          const onUploadProgress = (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total);
            if (percent < 71) {
              const singleClickImages = [...this.state.singleClickImages];

              singleClickImages[i] = {
                ...singleClickImages[i],
                percent: percent,
              };
              this.setState({
                singleClickImages: singleClickImages,
              });
            }
          };

          stockAPI(
            data,
            "POST",
            "/submitBill",
            null,
            onUploadProgress,
            cancelToken,
            true
          )
            .then((res) => {
              if (res.statusCode === 100 && res.response) {
                if (res.response && res.response.is_manual_process === "0") {
                  this.fileArray = [];
                  this.fileObj = [];
                  const singleClickImages = [...this.state.singleClickImages];
                  singleClickImages[i] = {
                    ...singleClickImages[i],
                    percent: 100,
                    status: "complete",
                    pointsEarned: res.response.points_earned,
                    ...res.response,
                  };
                  this.setState({
                    singleClickImages: singleClickImages,
                    doneBtnStatus: false,
                    removeImageId: "",
                  });
                } else if (
                  res.response &&
                  res.response.is_manual_process === "1"
                ) {
                  this.fileArray = [];
                  this.fileObj = [];
                  const singleClickImages = [...this.state.singleClickImages];
                  singleClickImages[i] = {
                    ...singleClickImages[i],
                    percent: 100,
                    status: "manual",
                    pointsEarned: res.response.points_earned,
                    ...res.response,
                  };
                  this.setState({
                    doneBtnStatus: false,
                    singleClickImages: singleClickImages,
                    removeImageId: "",
                  });
                }
              } else if (
                res.statusCode === 110 &&
                res.response &&
                res.response !== ""
              ) {
                openAlertBox(res.statusDescription, "error");
                this.fileArray = [];
                this.fileObj = [];
                const singleClickImages = [...this.state.singleClickImages];
                singleClickImages[i] = {
                  ...singleClickImages[i],
                  percent: 100,
                  status: "errorCode",
                  duplicate: true,
                };
                this.setState({
                  doneBtnStatus: false,
                  singleClickImages: singleClickImages,
                  removeImageId: "",
                });
              } else {
                if (
                  res.message &&
                  res.message === singleCancel &&
                  this.state.removeImageId !== ""
                ) {
                  const my_id = this.state.removeImageId;
                  this.fileArray = [];
                  this.fileObj = [];
                  this.setState((prevState) => ({
                    doneBtnStatus: false,
                    singleClickImages: prevState.singleClickImages.filter(
                      (img) => img.my_id !== my_id
                    ),
                    removeImageId: "",
                  }));
                } else {
                  if (res && res.statusDescription) {
                    openAlertBox(res && res.statusDescription, "error");
                  } else if (res && res.message) {
                    openAlertBox(res && res.message, "error");
                  } else {
                    openAlertBox(genericBillFailedMessage, "error");
                  }

                  this.fileArray = [];
                  this.fileObj = [];
                  const singleClickImages = [...this.state.singleClickImages];
                  singleClickImages[i] = {
                    ...singleClickImages[i],
                    percent: 100,
                    status: "errorCode",
                  };
                  this.setState({
                    doneBtnStatus: false,
                    singleClickImages: singleClickImages,
                    removeImageId: "",
                  });
                }
              }
            })
            .catch((err) => {
              openAlertBox(err && err.message, "error");
              this.fileArray = [];
              this.fileObj = [];
              const singleClickImages = [...this.state.singleClickImages];
              singleClickImages[i] = {
                ...singleClickImages[i],
                percent: 100,
                status: "errorCode",
              };
              this.setState({
                doneBtnStatus: false,
                singleClickImages: singleClickImages,
                removeImageId: "",
              });
            });
        } else {
          this.props.logoutUser();
        }
      }
    }
  };

  //cancel upload in single click bill image
  uploadCancel = (id) => {
    this.setState(
      {
        removeImageId: id,
      },
      () => {
        this.cancel(singleCancel);
      }
    );
  };

  deleteUploadedBill = (id) => {
    const deleteBill = this.state.singleClickImages.find(
      (bill) => bill.my_id === id
    );

    if (deleteBill && deleteBill.last_bill_id) {
      const deleteParams = new FormData();
      deleteParams.append("bill_id", deleteBill.last_bill_id);
      stockAPI(
        deleteParams,
        "POST",
        "/deleteSubmitBill",
        null,
        null,
        null,
        true
      ).then((response) => {
        if (response.statusCode === 100) {
          this.fileArray = [];
          this.fileObj = [];
          this.props.updateSubmitBillDoneBtn(false);
          this.setState((prevState) => ({
            doneBtnStatus: false,
            singleClickImages: prevState.singleClickImages.filter(
              (img) => img.my_id !== id
            ),
          }));
          if (response.statusDescription && response.statusDescription !== "") {
            openAlertBox("Bill deleted successfully.", "success");
          }
        } else {
          openAlertBox("Oops something went wrong!", "error");
        }
      });
    }
  };

  //handle done button on single click bill tab
  handleDoneBtn = async () => {
    if (this.state.singleClickImages.length > 0) {
      await this.setState({
        shouldBlockNavigation: false,
      });
      await this.props.updateImagesToUpload(this.state.singleClickImages);
      this.props.uploadImages();
      this.props.showProgress();
    }

    this.props.history.push({
      pathname: "/thank-you",
    });
  };

  //clicked on the bill to view the details of single click bill image uploaded
  handleBillClicked = (bill) => {
    if (this.state.doneBtnStatus) {
      return;
    }

    if (bill.last_bill_id) {
      if (getStorageItem("user")) {
        let data = new FormData();
        data.append("customer_id", JSON.parse(getStorageItem("user")).id);
        data.append("merchant_id", apiData.merchant_id);
        data.append("platform", apiData.platform);
        data.append("bill_ids", bill.last_bill_id);

        stockAPI(data, "POST", "/getBillSummary")
          .then(async (response) => {
            if (response) {
              const bill_data = {
                fileName: bill.file.name,
                ...response.bill_data[`${bill.last_bill_id}`],
              };
              await this.setState({
                singleBillDetailPopup: true,
                singleBillDetailSelected: bill_data,
              });
            } else {
              openAlertBox("Oops something went wrong!", "error");
            }
          })
          .catch(async (err) => {
            openAlertBox(err && err.message, "error");
          });
      } else {
        this.props.logoutUser();
      }
    } else {
      openAlertBox("Bill not uploaded.", "error");
    }
  };

  //clicked on the bill to view the details of single click bill image uploaded closed
  handleBillClickedClosed = () => {
    this.setState({
      singleBillDetailPopup: false,
      singleBillDetailSelected: null,
    });
  };

  //clicked on the image to view the details of single click bill image uploaded
  handleBillImageClicked = (imageSrc, isPdf) => {
    if (isPdf) {
      window.open(imageSrc);
    } else {
      this.setState({
        singleBillImageSelected: imageSrc,
        singleBillImagePopup: true,
      });
    }
  };

  //clicked on the image to view the details of single click bill image uploaded closed
  handleBillImageClickedClosed = () => {
    this.setState({
      singleBillImagePopup: false,
      singleBillImageSelected: null,
    });
  };

  //handle change in multi click form for select
  handleSelectChange = async (e) => {
    const { name, value } = e.target;
    let change = {};
    change[name] = value;
    if (name === "location")
      await this.setState({
        billDate: new Date(),
        brand: 0,
      });
    this.setState(change, () => {
      if (name === "location") {
        this.getBrands(this.state.location);

        // this.updateValidationStatus();
        if (this.state.location !== 0 && this.state.locations.length !== 0) {
          const selectedLocation = this.state.locations.find(
            (loc) => loc.id === this.state.location
          );
          this.setState({
            no_days: selectedLocation.bill_date_submission_days,
            shouldBlockNavigation: true,
          });
        }

        if (!this.state.locationValidation.touched) {
          this.setState((prevState) => ({
            locationValidation: {
              ...prevState.locationValidation,
              touched: true,
            },
            shouldBlockNavigation: true,
          }));
        } else if (
          this.state.locationValidation.touched &&
          this.state.location === 0
        ) {
          this.setState((prevState) => ({
            locationValidation: {
              ...prevState.locationValidation,
              error: true,
              message: "Location not available.",
            },
          }));
        }
      } else if (name === "brand") {
        this.updateValidationStatus();
        if (!this.state.brandValidation.touched) {
          this.setState((prevState) => ({
            brandValidation: {
              ...prevState.brandValidation,
              touched: true,
            },
            shouldBlockNavigation: true,
          }));
        } else if (
          this.state.brandValidation.touched &&
          this.state.brand === 0
        ) {
          this.setState((prevState) => ({
            brandValidation: {
              ...prevState.brandValidation,
              error: true,
              message: "Brand not available.",
            },
          }));
        }
      }
    });
  };

  onBlurMultiChange = (e) => {
    const { name } = e.target;
    if (name === "billNumber" && !this.state.billNumberValidation.touched) {
      this.setState((prevState) => ({
        ...prevState,
        billNumberValidation: {
          ...prevState.billNumberValidation,
          touched: true,
        },
      }));
    } else if (
      name === "billAmount" &&
      !this.state.billAmountValidation.touched
    ) {
      this.setState((prevState) => ({
        ...prevState,
        billAmountValidation: {
          ...prevState.billAmountValidation,
          touched: true,
        },
      }));
    }
  };

  handleBillAountChange = (e) => {
    const { value } = e.target;
    // const numbericRegex = /^[0-9]+$/;
    const numbericRegex = /^[0-9]*(\.[0-9]{0,2})?$/;
    if (value.trim() === "") {
      this.setState(
        (prevState) => ({
          ...prevState,
          billAmount: value,
          shouldBlockNavigation: true,
          billAmountValidation: {
            touched: true,
            error: true,
            message: requiredMessage,
          },
        }),
        () => {
          this.updateValidationStatus();
        }
      );
    } else if (value.match(numbericRegex)) {
      if (value.length < 7) {
        this.setState(
          (prevState) => ({
            ...prevState,
            shouldBlockNavigation: true,
            billAmount: value,
            billAmountValidation: {
              touched: true,
              error: false,
              message: "",
            },
          }),
          () => {
            this.updateValidationStatus();
          }
        );
      } else {
        this.setState(
          (prevState) => ({
            shouldBlockNavigation: true,
            ...prevState,
            billAmountValidation: {
              touched: true,
              ...prevState.billAmountValidation,
            },
          }),
          () => {
            this.updateValidationStatus();
          }
        );
      }
    } else {
      this.setState(
        (prevState) => ({
          ...prevState,
          billAmount: value,
          shouldBlockNavigation: true,
          billAmountValidation: {
            touched: true,
            error: true,
            message: numericValueMessage,
          },
        }),
        () => {
          this.updateValidationStatus();
        }
      );
    }
  };

  handleBillNumberChange = (e) => {
    const { value } = e.target;
    const regex = /^[a-zA-Z0-9-#/]*$/;
    if (value.trim() === "") {
      this.setState(
        (prevState) => ({
          ...prevState,
          billNumber: value,
          shouldBlockNavigation: true,
          billNumberValidation: {
            touched: true,
            error: true,
            message: requiredMessage,
          },
        }),
        () => {
          this.updateValidationStatus();
        }
      );
    } else {
      if (regex.test(value.trim())) {
        this.setState(
          (prevState) => ({
            ...prevState,
            billNumber: value,
            shouldBlockNavigation: true,
            billNumberValidation: {
              touched: true,
              error: false,
              message: "",
            },
          }),
          () => {
            this.updateValidationStatus();
          }
        );
      } else {
        this.setState(
          (prevState) => ({
            ...prevState,
            billNumber: value,
            shouldBlockNavigation: true,
            billNumberValidation: {
              touched: true,
              error: true,
              message: "Enter valid bill number.",
            },
          }),
          () => {
            this.updateValidationStatus();
          }
        );
      }
    }
  };

  updateValidationStatus = () => {
    const valid = this.checkValidity();
    this.setState({
      submitBtnStatus: !valid,
    });
  };

  //date change handler
  handleDateChange = (date) => {
    this.setState({
      billDate: date._d,
    });
  };

  multiImageSelect = async (e) => {
    const files = e.target.files;
    e.persist();

    const filesUploaded = this.state.multiClickImages.length;
    let allowUpload = 0;
    allowUpload = 5 - filesUploaded;
    if (allowUpload > files.length) {
      allowUpload = files.length;
    }

    if (filesUploaded < 6 && this.state.multiClickImagesSize < 20971520) {
      if (files && files.length > 0) {
        for (let i = 0; i < allowUpload; i++) {
          if (
            files[i] &&
            (files[i].type === "image/png" ||
              files[i].type === "image/jpeg" ||
              files[i].type === "image/jpg" ||
              files[i].type === "application/pdf")
          ) {
            if (platform.product === "iPhone") {
              let size = this.state.multiClickImagesSize;
              size = size + files[i].size;
              if (size < 20971520) {
                await this.setState(
                  {
                    multiClickImagesSize: size,
                  },
                  async () => {
                    const obj = {
                      file: files[i],
                      uri: URL.createObjectURL(files[i]),
                      my_id: this.generateId(),
                      pdf: files[i].type === "application/pdf",
                    };

                    await this.setState(
                      (prevState) => ({
                        multiClickImages: [...prevState.multiClickImages, obj],
                        shouldBlockNavigation: true,
                      }),
                      async () => {
                        await this.setState({
                          submitBtnStatus: !this.checkValidity(),
                        });
                      }
                    );
                  }
                );
              } else {
                openAlertBox("Images size exceeding allowed limit!", "error");
                break;
              }
            } else {
              let uri = await this.getUriForFile(files[i]);
              if (uri) {
                let size = this.state.multiClickImagesSize;
                size = size + files[i].size;
                if (size < 20971520) {
                  await this.setState({
                    multiClickImagesSize: size,
                  });
                  const obj = {
                    file: files[i],
                    uri: uri,
                    my_id: this.generateId(),
                    pdf: files[i].type === "application/pdf",
                  };

                  await this.setState(
                    (prevState) => ({
                      multiClickImages: [...prevState.multiClickImages, obj],
                      shouldBlockNavigation: true,
                    }),
                    async () => {
                      await this.setState({
                        submitBtnStatus: !this.checkValidity(),
                      });
                    }
                  );
                } else {
                  openAlertBox("Images size exceeding allowed limit!", "error");
                  break;
                }
              } else {
                openAlertBox("Error reading the file.", "error");
              }
            }
          } else {
            openAlertBox(errorMessageImageFile, "error");
          }
        }
      } else {
        openAlertBox("No files selected", "error");
      }
    } else {
      openAlertBox(
        "Already selected 5 images or Image size exceeds 20 mb",
        "error"
      );
    }
    e.target.value = "";
  };

  deleteImageHandler = (id, size) => {
    this.setState((prevState) => ({
      multiClickImages: prevState.multiClickImages.filter(
        (bill) => bill.my_id !== id
      ),
      multiClickImagesSize: prevState.multiClickImagesSize - size,
    }));
  };

  deleteImageHandler1 = (id) => {
    this.setState((prevState) => ({
      singleClickImages: prevState.singleClickImages.filter(
        (bill) => bill.my_id !== id
      ),
    }));
  };

  cancelUploadHandler = (id) => {
    this.cancelMulti(multiCancel);
  };

  submitBtnHandler = async (e) => {
    e.preventDefault();
    await this.setState({
      multiFormSubmitted: true,
      submitBtnStatus: true,
      shouldBlockNavigation: false,
    });

    if (getStorageItem("user")) {
      let data = new FormData();
      // data.append('mobile_number', JSON.parse(getStorageItem('user')).mobile_number);
      data.append("customer_id", JSON.parse(getStorageItem("user")).id);
      data.append("merchant_id", apiData.merchant_id);
      data.append("platform", apiData.platform);
      data.append("bill_upload_type", 2);
      data.append("brand_id", this.state.brand);
      data.append("location_id", this.state.location);
      data.append("bill_number", this.state.billNumber.trim());
      data.append("bill_amount", this.state.billAmount.trim());
      data.append(
        "bill_date",
        `${this.state.billDate.getFullYear()}-${
          this.state.billDate.getMonth() + 1
        }-${this.state.billDate.getDate()}`
      );
      for (let i = 0; i < this.state.multiClickImages.length; i++) {
        data.append("bill_image[]", this.state.multiClickImages[i].file);
      }

      let CancelToken = axios.CancelToken;
      const cancelToken = new CancelToken((c) => {
        this.cancelMulti = c;
      });

      const onUploadProgress = (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        if (percent < 100) {
          this.setState({
            multiImagesUploadPercent: percent,
          });
        }
      };

      stockAPI(
        data,
        "POST",
        "/submitBill",
        null,
        onUploadProgress,
        cancelToken,
        true
      )
        .then(async (res) => {
          if (res) {
            await this.setState({
              multiImagesUploadPercent: 0,
              multiFormSubmitted: false,
              submitBtnStatus: false,
            });
            if (res.statusCode === 100 && res.response) {
              this.props.history.push({
                pathname: "/bill-summary",
                state: {
                  billIds: res.response && res.response.last_bill_id,
                  isMultiClickBill: true,
                },
              });
            } else if (res.message && res.message === multiCancel) {
              return;
            } else {
              if (res && res.statusDescription) {
                openAlertBox(res && res.statusDescription, "error");
              } else if (res && res.message) {
                openAlertBox(res && res.message, "error");
              } else {
                openAlertBox(genericBillFailedMessage, "error");
              }
            }
          } else {
            openAlertBox("Oops something went wrong!", "error");
          }
        })
        .catch((err) => {
          openAlertBox(err && err.message, "error");
          this.setState({
            multiImagesUploadPercent: 0,
            multiFormSubmitted: false,
            submitBtnStatus: false,
          });
        });
    } else {
      this.props.logoutUser();
    }
  };

  UnsavedChangesWarning = () => (
    <UnsavedChangesWarning
      when={this.state.shouldBlockNavigation}
      tabValue={this.state.tabValue}
      handleYes={this.handleYes}
      //
      handleNo={this.handleNo}
      isDirty={this.state.isDirty}
      // Navigate function
      navigate={(path) => this.props.history.push(path)}
    />
  );

  checkValidity = () => {
    if (
      this.state.location &&
      this.state.brand &&
      !this.state.billNumberValidation.error &&
      !this.state.billAmountValidation.error &&
      this.state.billNumber.trim() &&
      this.state.billAmount.trim() &&
      this.state.billDate &&
      this.state.multiClickImages.length > 0
    ) {
      return true;
    }
    return false;
  };

  toggleMoreText = () => {
    this.setState((prevState) => ({
      moreText: !prevState.moreText,
    }));
  };

  detectPageScroll = (e) => {
    // this.setState({
    // 	scrollPos: document.body.getBoundingClientRect().bottom,
    // 	show: document.body.getBoundingClientRect().bottom < this.state.scrollPos
    // });

    this.setState({
      scrollPos: document.body.getBoundingClientRect().top,
      show: document.body.getBoundingClientRect().top > this.state.scrollPos,
    });

    // this.prev = window.scrollY;
  };

  render() {
    const { classes } = this.props;

    const {
      tabValue,
      singleClickImages,
      doneBtnStatus,
      singleBillDetailSelected,
      singleBillDetailPopup,
      singleBillImagePopup,
      singleBillImageSelected,
      submitBtnStatus,
      multiClickImagesSize,
      multiFormSubmitted,
      moreText,
    } = this.state;

    let upload_box = (
      <UploadBoxWithIcon
        mainTitle={
          this.state.singleClickImages.length === 0
            ? "Upload your single bill images"
            : "Add more single bill images"
        }
        handleClick={this.selectImage}
        secondaryTitle="each image max 10MB only."
        disabled={doneBtnStatus}
      />
    );
    if (singleClickImages.length > 0) {
      upload_box = null;
    }

    const tabContainer = (
      <Paper
        className={classnames(classes.tabContainer, {
          [classes.brLTB]: tabValue === 0,
          [classes.brRTB]: tabValue === 1,
        })}
      >
        <Tabs
          classes={{
            indicator: classnames(classes.indicator, {
              [classes.firstIndicator]: tabValue === 0,
              [classes.lastIndicator]: tabValue === 1,
            }),
          }}
          value={tabValue}
          onChange={this.handleTabValueChange}
          centered
        >
          <Tab
            label="Short Bill"
            className={classes.tab}
            classes={{ selected: classes.selected }}
            disableRipple
            disabled={
              doneBtnStatus || multiFormSubmitted || !this.props.isShortBill
            }
          />
          <Tab
            label="Long Bill"
            className={classnames(classes.tab, {
              [classes.overiteTab]: !this.props.isShortBill,
            })}
            classes={{ selected: classes.selected }}
            disableRipple
            disabled={doneBtnStatus || multiFormSubmitted}
          />
        </Tabs>
      </Paper>
    );

    let done_button = null;
    if (singleClickImages.length > 0) {
      done_button = (
        <div
          // className={`${classes.btnContainer} ${this.state.show ? classes.active : classes.hidden}`}
          className={classes.btnContainer}
        >
          {singleClickImages.length > 0 && singleClickImages.length < 5 ? (
            <div className={classes.plusBtnContainer}>
              <PlusButton handleClick={this.selectImage} />
            </div>
          ) : null}
          <div style={{ width: "100%" }}>
            <Button
              className={classes.doneBtn}
              disableRipple
              disabled={doneBtnStatus}
              color="primary"
              variant="contained"
              onClick={this.handleDoneBtn}
            >
              SUBMIT BILL(S)
            </Button>
          </div>
        </div>
      );
    }

    let infoText = (
      <Grid className={classes.info_box}>
        <Typography className={classes.main_title}>
          {tabValue === 0
            ? "Short Bill?"
            : this.props.isShortBill
            ? "Long Bill?"
            : ""}
        </Typography>
        <Typography
          className={classnames(classes.subtitle, {
            [classes.mTop_10]: !this.props.isShortBill,
          })}
        >
          {tabValue === 0
            ? "Use this section if you can capture the entire bill clearly in one/single image. "
            : "Use this section to upload multiple images of a single bill. "}

          {moreText ? (
            <Fragment>
              <Typography className={classes.subtitle} component="span">
                {tabValue === 0
                  ? "All your bills that can be easily clicked fit into one single photo frame are Short Bills. "
                  : "The bills that do not fit into one single photo frame, and need to be clicked in multiple pictures are Long Bills. "}
              </Typography>
              <Typography
                className={classes.subtitle_learn}
                onClick={this.toggleMoreText}
                component="span"
              >
                Less
              </Typography>
            </Fragment>
          ) : (
            <Typography
              className={classes.subtitle_learn}
              onClick={this.toggleMoreText}
              component="span"
            >
              Learn more.
            </Typography>
          )}
        </Typography>
      </Grid>
    );

    return (
      <Grid
        className={
          tabValue === 0
            ? classes.top
            : this.props.isShortBill
            ? classes.top1
            : classes.rm_pd
        }
      >
        <Grid className={classes.pageContainer}>
          <Grid item className={classes.pageItem}>
            {this.props.isShortBill && tabContainer}
          </Grid>
          <Grid
            item
            className={classes.pageItem + " " + classes.main_page_item}
          >
            {tabValue === 0 ? (
              <Grid className={classes.root}>
                <Grid>
                  <Grid style={{ width: "100%" }}>
                    {infoText}
                    {upload_box}
                    <Grid className={classes.allFilesContainer}>
                      {singleClickImages.length > 0
                        ? singleClickImages.map((img) => (
                            <SingleClickImage
                              key={img.my_id}
                              bill_src={img.uri}
                              bill_name={img.file && img.file.name}
                              bill_size={img.file && img.file.size}
                              onImageClicked={this.handleBillImageClicked}
                              id={img.my_id}
                              deleteHandler={this.deleteImageHandler1}
                              isPdf={img.pdf}
                            />
                          ))
                        : null}
                    </Grid>
                  </Grid>
                  <Dialog
                    open={singleBillDetailPopup}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.handleBillClickedClosed}
                    maxWidth="md"
                  >
                    <BillDetailDialog
                      okBtnHandler={this.handleBillClickedClosed}
                      fileName={
                        singleBillDetailSelected &&
                        singleBillDetailSelected.fileName
                      }
                      brand={
                        singleBillDetailSelected &&
                        singleBillDetailSelected.brand_name
                      }
                      pointsEarned={
                        singleBillDetailSelected &&
                        singleBillDetailSelected.points_earned
                      }
                      status={
                        singleBillDetailSelected &&
                        singleBillDetailSelected.status
                      }
                      billNumber={
                        singleBillDetailSelected &&
                        singleBillDetailSelected.bill_number
                      }
                      billDate={
                        singleBillDetailSelected &&
                        singleBillDetailSelected.bill_date
                      }
                      billAmount={
                        singleBillDetailSelected &&
                        singleBillDetailSelected.bill_amount
                      }
                      billImages={
                        singleBillDetailSelected &&
                        singleBillDetailSelected.bill_image
                      }
                      location={
                        singleBillDetailSelected &&
                        singleBillDetailSelected.location_name
                      }
                    />
                  </Dialog>
                  <Dialog
                    open={singleBillImagePopup}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.handleBillImageClickedClosed}
                    maxWidth="md"
                    className={classes.dialogModal}
                  >
                    <BillImage
                      multi={false}
                      open={singleBillImagePopup}
                      imgSrc={singleBillImageSelected}
                      okBtnHandler={this.handleBillImageClickedClosed}
                    />
                  </Dialog>
                </Grid>
              </Grid>
            ) : (
              <Grid className={classes.root}>
                {infoText}
                <LongBill
                  allLocations={this.state.locations}
                  allBrands={this.state.brands}
                  multiClickImages={this.state.multiClickImages}
                  selectedLocation={this.state.location}
                  selectedBrand={this.state.brand}
                  enteredBillNumber={this.state.billNumber}
                  selectedBillDate={this.state.billDate}
                  enteredBillAmount={this.state.billAmount}
                  submitBtnStatus={submitBtnStatus}
                  handleSelectChange={this.handleSelectChange}
                  handleBillAountChange={this.handleBillAountChange}
                  handleBillNumberChange={this.handleBillNumberChange}
                  handleDateChange={this.handleDateChange}
                  allImageSize={multiClickImagesSize}
                  handleImageSelection={this.multiImageSelect}
                  uploadPercent={this.state.multiImagesUploadPercent}
                  deleteHandler={this.deleteImageHandler}
                  cancelUploadHandler={this.cancelUploadHandler}
                  submitBtnHandler={this.submitBtnHandler}
                  stopSelection={multiFormSubmitted}
                  onBlurMultiChange={this.onBlurMultiChange}
                  validationBillAmount={this.state.billAmountValidation}
                  validationBillNumber={this.state.billNumberValidation}
                  validationLocation={this.state.locationValidation}
                  validationBrand={this.state.brandValidation}
                  numberOfDays={this.state.no_days}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        {done_button}
        {this.UnsavedChangesWarning()}
      </Grid>
    );
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(SubmitBill));
